import React, { useState } from "react";
import DocumentTypeSelection from "./components/DocumentTypeSelection";
import CameraCapture from "./components/CameraCapture";
import DocumentForm from "./components/DocumentForm";

const App = () => {
  const [documentType, setDocumentType] = useState("");
  const [extractedData, setExtractedData] = useState(null);

  const handleDocumentTypeSelection = (type) => {
    setDocumentType(type);
  };

  const handleImageCaptured = (data) => {
    // Aquí recibes los datos extraídos del backend
    setExtractedData(data);
  };

  return (
    <div>
      {!documentType && (
        <DocumentTypeSelection onSelectType={handleDocumentTypeSelection} />
      )}
      {documentType && !extractedData && (
        <CameraCapture
          documentType={documentType}
          onCapture={handleImageCaptured}
        />
      )}
      {extractedData && <DocumentForm extractedData={extractedData} />}
    </div>
  );
};

export default App;
