import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import config from "../config";

const DocumentTypeSelection = ({
  onSelectType,
  logoUrl = "https://www.civitfun.com/public/img/logotipos/logo-civitfun.svg",
}) => {
  const userLanguage = navigator.language || navigator.userLanguage;
  const isSpanish = userLanguage.startsWith("es");

  const handleFileUpload = async (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        const imageData = reader.result;
        console.log(imageData);
        try {
          await sendToBackend(imageData);
        } catch (error) {
          console.error("Error uploading file: ", error);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const sendToBackend = async (imageData) => {

    // Convertir la imagen a Blob
    const blob = await fetch(imageData).then((res) => res.blob());

    const formData = new FormData();
    formData.append("file", blob, "captured_image.png");

    const backendUrl = config.backendUrl;
    
    try {
      const response = await fetch(`${backendUrl}/api/v1/extract`, {
        method: "POST",
        body: formData,
      });

      const result = await response.json();
      if (response.ok) {
        // La respuesta del backend contiene los datos extraídos
        console.log("Datos extraídos:", result.data);
        onSelectType("upload_complete", result.data); // Pasa los datos extraídos al componente padre
      } else {
        setError("Error en el servidor: " + result.error);
      }
    } catch (err) {
      setError("Error al enviar la imagen: " + err.message);
    } finally {
      console.log('Finally: ')
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        mt: 5,
        px: 2,
      }}
    >
      {logoUrl && (
        <Box sx={{ mb: 3 }}>
          <img src={logoUrl} alt="Logo" style={{ maxHeight: "100px" }} />
        </Box>
      )}
      <Typography variant="h4" gutterBottom>
        {isSpanish
          ? "Selecciona una opción para cargar tu documento"
          : "Select an option to upload your document"}
      </Typography>

      <Grid container spacing={3} justifyContent="center" sx={{ mt: 2 }}>
        <Grid item xs={12} sm={6} md={4}>
          <Card variant="outlined" sx={{ textAlign: "center", border: "none" }}>
            <CardContent>
              <Button
                variant="contained"
                color="primary"
                onClick={() => onSelectType("capture_live")}
                startIcon={<CameraAltIcon />}
                fullWidth
                sx={{ height: 80 }}
              >
                {isSpanish ? "Usar Cámara" : "Use Camera"}
              </Button>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Card variant="outlined" sx={{ textAlign: "center", border: "none" }}>
            <CardContent>
              <Button
                variant="contained"
                color="warning"
                component="label"
                startIcon={<UploadFileIcon />}
                fullWidth
                sx={{ height: 80 }}
              >
                {isSpanish ? "Subir Archivo" : "Upload File"}
                <input
                  type="file"
                  hidden
                  onChange={(e) => handleFileUpload(e.target.files[0])}
                />
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DocumentTypeSelection;
