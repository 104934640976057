import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const DocumentForm = ({ extractedData }) => {
  const [formData, setFormData] = useState({});

  useEffect(() => {
    // Inicializa el formulario con los datos extraídos
    setFormData(extractedData);
  }, [extractedData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Formulario enviado:", formData);
    // Aquí puedes realizar alguna acción como enviar los datos al backend
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{ maxWidth: "600px", mx: "auto", mt: 5 }}
    >
      <Typography variant="h4" gutterBottom>
        Formulario de Documento
      </Typography>
      {Object.keys(formData).map((field, index) => (
        <TextField
          key={index}
          label={field}
          name={field}
          value={formData[field] || ""}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
      ))}
      <Button variant="contained" color="primary" type="submit" sx={{ mt: 3 }}>
        Submit
      </Button>
    </Box>
  );
};

export default DocumentForm;
