// CameraCapture.js
import React, { useRef, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import config from "../config";

const CameraCapture = ({ onCapture, onBack }) => {
  const videoRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Acceder a la cámara del usuario
    navigator.mediaDevices
      .getUserMedia({ video: { width: 640, height: 360 } })
      .then((stream) => {
        videoRef.current.srcObject = stream;
      })
      .catch((err) => {
        console.error("Error accessing camera: ", err);
        setError("Unable to access the camera.");
      });
  }, []);

  const captureImage = () => {
    const canvas = document.createElement("canvas");
    const video = videoRef.current;
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
    const imageData = canvas.toDataURL("image/png");

    // Enviar la imagen al backend
    sendToBackend(imageData);
  };

  const sendToBackend = async (imageData) => {
    setLoading(true);
    setError(null);

    // Convertir la imagen a Blob
    const blob = await fetch(imageData).then((res) => res.blob());

    const formData = new FormData();
    formData.append("file", blob, "captured_image.png");

    const backendUrl = config.backendUrl;
    
    try {
      const response = await fetch(`${backendUrl}/api/v1/extract`, {
        method: "POST",
        body: formData,
      });

      const result = await response.json();
      if (response.ok) {
        // La respuesta del backend contiene los datos extraídos
        console.log("Datos extraídos:", result.data);
        onCapture(result.data); // Pasa los datos extraídos al componente padre
      } else {
        setError("Error en el servidor: " + result.error);
      }
    } catch (err) {
      setError("Error al enviar la imagen: " + err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        maxWidth: "800px",
        margin: "auto",
        mt: 5,
        textAlign: "center",
      }}
    >
      <Button
        variant="outlined"
        color="warning"
        startIcon={<ArrowBackIcon />}
        onClick={onBack}
        sx={{ mb: 2, width: "100%", height: "56px" }}
      >
        Back
      </Button>
      <Typography variant="h5" gutterBottom>
        Please, position your document inside the frame
      </Typography>
      {error && (
        <Typography color="error" sx={{ mb: 2 }}>
          {error}
        </Typography>
      )}

      <Box
        sx={{
          position: "relative",
          width: {
            xs: "90%",
            sm: "100%",
          },
          height: {
            xs: "60vh",
            sm: 0,
          },
          paddingTop: {
            xs: 0,
            sm: "56.25%", // 16:9 aspect ratio for larger screens
          },
          border: "2px solid #ccc",
          borderRadius: "8px",
          overflow: "hidden",
          boxShadow: 3,
          mb: 3,
          margin: "auto",
        }}
      >
        <video
          ref={videoRef}
          autoPlay
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      </Box>

      <Button
        variant="contained"
        color="primary"
        onClick={captureImage}
        disabled={loading}
        sx={{ mt: 2, width: "100%", height: "56px" }}
      >
        {loading ? <CircularProgress size={24} /> : "Capture"}
      </Button>
    </Box>
  );
};

export default CameraCapture;
